import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import { Box } from '@3pc/layout-components-react';
import { footerHeight } from '../components/Footer';
import { headerHeight } from '../components/PageHeader';
import { sortItems } from '../utils';
import ListItemLink from '../base/ListItemLink';
import { devices } from '../styles/breakpoints';
import styled from '@emotion/styled';
import { colors } from '../styles/colors';

const Topic = styled.h3`
  margin-top: 20px;
  color: ${colors.blue};

  &:first-of-type {
    margin-top: 0;
  }

  @media ${devices.mobileMin} {
    margin-top: 28px;
  }
`;

const BookmarkList = styled.ol`
  margin-top: 10px;

  @media ${devices.mobileMin} {
    margin-top: 20px;
  }

  li a span {
    font-style: normal;
  }
`;

const NoBookmarks = styled.p`
  text-align: center;
  margin-top: 20px;

  @media ${devices.mobileMin} {
    margin-top: 28px;
  }
`;

const BookmarkPage = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const getListFromStorage = () => {
      // check if localStorage even exists, if not create it, else use it
      if (localStorage.getItem('Merkliste') === null) {
        return [];
      } else {
        const arrayString = localStorage.getItem('Merkliste');
        const array = JSON.parse(arrayString);

        return array;
      }
    };

    setList(getListFromStorage());
  }, []);

  const listNames = [];
  list.forEach((el) => {
    const slug = el.slug;
    const firstURLSection = slug.split('/')[1];
    listNames.push(firstURLSection);
  });
  const listTypes = [...new Set(listNames)].sort((a, b) => a.localeCompare(b));
  const listTitles = listTypes.map((el) => {
    el = el.charAt(0).toUpperCase() + el.slice(1);
    if (el === 'List') {
      return (el = 'Verfassung');
    } else {
      return el;
    }
  });

  return (
    <>
      <PageHeader headerTitle={'Gemerkt'} pageType={'list-page'} />
      <Layout>
        <Box
          mb={footerHeight.footerMobile}
          mt={headerHeight.headerMobile}
          padding="10px 20px"
        >
          {list.length ? (
            <Box as="ol" pt={['22px', '31px']} margin={['0', '0 15% 0 15%']}>
              {listTypes.map((listType, index) => (
                <li key={listType}>
                  <Topic>{listTitles[index]}</Topic>
                  <BookmarkList>
                    {list
                      .sort((a, b) => {
                        return sortItems(a.title, b.title);
                      })
                      .map((list) =>
                        list.slug.split('/')[1] === listType ? (
                          <ListItemLink
                            key={list.title}
                            link={list.slug}
                            info={list.title}
                          />
                        ) : (
                          ''
                        )
                      )}
                  </BookmarkList>
                </li>
              ))}
            </Box>
          ) : (
            <NoBookmarks>
              Sie haben sich noch keine Artikel, Hintergründe oder
              Lexikoneinträge gemerkt.
            </NoBookmarks>
          )}
        </Box>
      </Layout>
      <Footer />
    </>
  );
};

export default BookmarkPage;
